<template>
	<div>
	<DataTable
		v-if="show"
		:getQuery="queries.getByCompany(companyFilter)"
		:updateQuery="queries.update"
		:subscriptionQuery="queries.subscribeByCompany(companyFilter)"
		:createFields="createFields"
		:headers="headers"
		:conditions="conditions"
		:hide_add_button="true"
		icon="mdi-cart"
		result_name="marketRequests"
		title="market requests"
		subtitle="List of all market requests"
	>
	</DataTable>
	</div>
</template>
<script>
import DataTable from '@/components/datatable'

export default {
	components: {DataTable},
    data(){
		return {
			show:false,
			queries: require('@/gql/marketRequest.gql'),
			user: this.$store.getters['auth/getUserData'],
			conditions: {},
			companyFilter: '',
			createFields: [
				{ name:'status', type:"select", label: 'Status', icon: 'mdi-av-timer',
					items: ['pending', 'awaiting pickup', 'failed']
				},
			],
			headers: [
				{ text: 'Customer Name', align: 'left', value: 'customer.first_name' },
				{ text: 'Cost', align: 'left', value: 'cost', sortable: false },
				{ text: 'Status', align: 'center', value: 'status' },
				{ text: 'Payment', align: 'center', value: 'transaction.final_amount' },
				{ text: 'Items', align: 'center', value: 'button', button: {
					label: 'view',
					icon: 'mdi-cart',
					color: 'primary',
					text: true,
					show_length: 'products',
					rounded: true,
					to: {
						name: 'agency-view-customer-products',
						params: { key: 'products', value: 'products', filter: products=>{
							return product.company.id == this.user.company.id
						}}
					}
				},
					sortable: false
				},
				{ text: 'Request Date', align: 'left', value: 'createdAt'},
				{ text: 'Request Time', align: 'left', value: 'createdAt', time: true},
				// { text: 'Actions', value: 'action', sortable: false },
			],
		}
	},
	created(){
		this.companyFilter = this.user.company.id
		this.conditions = {
			products_some: { product: {company: {id: this.user.company.id}}}
		}
		this.show = true
	}
}
</script>
